/*.aside, .header, .footer, .mobile-header {
    display: none;
}
.wrapper {
    padding-left:0 !important;
}
.content {
    padding-top:0 !important;
}
.page-wrapper{
    padding:0 !important;
}*/
.check-in,.callback {
    background-color: unset !important;
}