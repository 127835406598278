//
//  CalendarDate
//

@use 'sass:math';

// .calendar-more {
//     border: 1px solid #DADADA;
//     height: 30px;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//     font-variant: tabular-nums;
//     list-style: none;
//     -webkit-font-feature-settings: 'tnum';
//     font-feature-settings: 'tnum', "tnum";
//     position: relative;
//     display: inline-block;
//     width: 100%;
//     height: 32px;
//     padding: 4px 11px;
//     color: rgba(0, 0, 0, 0.65);
//     font-size: 14px;
//     line-height: 1.5;
//     background-color: #fff;
//     background-image: none;
//     border: 1px solid #d9d9d9;
//     border-radius: 4px;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
// }

.show-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.selected-show {
    width: calc(100% - 30px);
    display: flex;
    overflow-x: auto;
}

.icon-calendar {
    width: 20px;
}

.calendar-month {
    position: relative;
}

.calendar-content {
    position: fixed;
    z-index: 99999;
    width: 300px;
    height: 205px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}

.year-number {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(40, 40, 40, 0.85);

}

.calendar-title {
    font-size: 1.8rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    display: flex;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0px 10px;
}

.month-show {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 12px;
}

.selected-show::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */

.selected-show::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background-color: transparent;
}

/* Handle */

.selected-show::-webkit-scrollbar-thumb {
    width: 2px !important;
    height: 6px !important;
    border-radius: 4px;
    background: rgba(111, 33, 212, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(103, 82, 197, 0.3);
}

.selected-item {
    background: #F0E5FF;
    color: #4B00A7;
    padding: 2px 5px;
    font-size: 12px;
    margin: 0px 2px;
    min-width: 70px;
}

.month-box {
    width: 32px;
    height: 32px;
    margin: 5px calc((100% - 32*3px) /6);
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.month-box:hover {
    background-color: #efe6f5;
}

.month-box-active {
    background-color: #7300FF;
    color: #fff;
}

.month-box-disable {
    cursor: not-allowed;
    background-color: #ccc;
}

.month-box-disable:hover {
    cursor: not-allowed;
    background-color: #ccc;
}


.calendar-days {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.calendar-days-content {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.other-days,
.now-days {
    width: calc(100% / 7);
    text-align: center;
}

.other-days {
    color: #999;
}

.now-days {
    color: #000;
}

.common-days-active {
    background-color: #7300FF;
    color: #fff;
}

.common-days:hover {
    background-color: #efe6f5;
}

.days-width {
    min-width: 95px;
}



.form-control {
	@include dark-mode {
		background-color: $dark-input-bg;
		color: $dark-input-color;

		&:not([class*='valid']) {
			border-color: $dark-input-border-color;
		}
	}

	&:focus {
		// @include box-shadow($box-shadow);
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		@include dark-mode {
			background-color: $dark-input-disabled-bg;
		}
	}
}