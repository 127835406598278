// stylelint-disable scale-unlimited/declaration-strict-value
// stylelint-disable selector-max-compound-selectors
// stylelint-disable selector-max-universal
@import './variables';
@import './time-column';

.rbc-slot-selection {
	position: absolute;
	z-index: 10;
	width: 100%;
	padding: $spacer * 0.25;
	background-color: $time-selection-bg-color;
	color: $time-selection-color;
	font-size: 75%;
}

.rbc-slot-selecting {
	cursor: move;
}

.rbc-time-view {
	@include dark-mode {
		border-color: $dark-calendar-border;
	}

	display: flex;
	overflow: hidden;
	width: 100%;
	min-height: 0;
	flex: 1;
	flex-direction: column;
	border: 1px solid $calendar-border;
	border-radius: $border-radius;

	.rbc-time-gutter {
		white-space: nowrap;
	}

	.rbc-allday-cell {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: content-box;
	}

	.rbc-allday-cell + .rbc-allday-cell {
		@include dark-mode {
			border-left-color: $dark-cell-border;
		}

		border-left: 1px solid $cell-border;
	}

	.rbc-allday-events {
		position: relative;
		z-index: 4;
	}

	.rbc-row {
		min-height: 2.5rem;
		box-sizing: border-box;
	}
}

.rbc-time-header {
	display: flex;
	flex: 0 0 auto; // should not shrink below height
	flex-direction: row;

	&.rbc-overflowing {
		@include dark-mode {
			border-right-color: $dark-cell-border;
		}

		border-right: 1px solid $cell-border;
	}

	.rbc-rtl &.rbc-overflowing {
		@include dark-mode {
			border-left-color: $dark-cell-border;
		}

		border-left: 1px solid $cell-border;
		border-right-width: 0;
	}

	> .rbc-row:first-child {
		@include dark-mode {
			border-bottom-color: $dark-cell-border;
		}

		border-bottom: 1px solid $cell-border;
	}

	> .rbc-row.rbc-row-resource {
		@include dark-mode {
			border-bottom-color: $dark-cell-border;
		}

		border-bottom: 1px solid $cell-border;
	}

	// .rbc-gutter-cell {
	//   flex: none;
	// }

	// > .rbc-gutter-cell + * {
	//   width: 100%;
	// }
}

.rbc-time-header-cell-single-day {
	display: none;
}

.rbc-time-header-content {
	@include dark-mode {
		border-left-color: $dark-cell-border;
	}

	display: flex;
	min-width: 0;
	flex: 1;
	flex-direction: column;
	border-left: 1px solid $cell-border;

	.rbc-rtl & {
		@include dark-mode {
			border-right-color: $dark-cell-border;
		}

		border-right: 1px solid $cell-border;
		border-left-width: 0;
	}

	> .rbc-row.rbc-row-resource {
		@include dark-mode {
			border-bottom-color: $dark-cell-border;
		}

		flex-shrink: 0;
		border-bottom: 1px solid $cell-border;
	}
}

.rbc-time-content {
	@include dark-mode {
		border-top-color: $dark-calendar-border;
	}

	position: relative;
	display: flex;
	width: 100%;
	flex: 1 0 0%;
	align-items: flex-start;
	border-top: 2px solid $calendar-border; // stylelint-disable-line
	overflow-y: auto;

	> .rbc-time-gutter {
		flex: none;
	}

	> * + * > * {
		@include dark-mode {
			border-left-color: $dark-cell-border;
		}

		border-left: 1px solid $cell-border;
	}

	.rbc-rtl & > * + * > * {
		@include dark-mode {
			border-right-color: $dark-cell-border;
		}

		border-right: 1px solid $cell-border;
		border-left-width: 0;
	}

	> .rbc-day-slot {
		width: 100%;
		user-select: none;
	}
}

.rbc-current-time-indicator {
	position: absolute;
	z-index: 3;
	right: 0;
	left: 0;
	height: 1px;
	background-color: $current-time-color;
	pointer-events: none;
}

// stylelint-enable scale-unlimited/declaration-strict-value
// stylelint-enable selector-max-compound-selectors
// stylelint-enable selector-max-universal
