
.form-control:disabled {
    border: unset;
    background-color: white !important;
    box-shadow: unset;
}
.psl {
    padding: 0.5rem 1rem;
    padding-left: 1.5rem;
    font-weight: 600;
}
.titleTop {
    margin: 10px 0px 10px 0px;
    display: flex;
}

.title1 {
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    font-weight: 400;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.title2 {
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    outline: unset;
}

.tw1 {
    width: 64%;
}

.title3 {
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    font-weight: 400;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.tw2 {
    width: 78%;
}

.delete {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 5px 10px;
    font-size: 12px;
    height: 30px;
}