
table {
    table-layout: fixed;
}
.tbox .form-check {
    display: flex;
    justify-content: center;
}
a{
    text-decoration:none;
}
.btn-light-info a:hover {
    color: white;
}

[os=Windows] ::-webkit-scrollbar {
    width: 3px;
}
[os=Windows] ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
}